import axios from 'axios';

export function register(email: string, name: string, password: string) {
    const test = {
        email: 'clientTest@gmail.com',
        password: '12345',
        name: 'client test'
    }
    try {
//         host: string;
//   port: number;
//   auth?: AxiosBasicCredentials;
//   protocol?: string;
        return axios.post('http://152.44.43.166:3000/auth/register', test);
    }
    catch (err) {
        console.error("Register Error: ", err);
    }
}